<template>
  <el-drawer
    v-model="showSettings"
    :withHeader="false"
    direction="rtl"
    size="300px"
  >
    <div class="setting-drawer-title">
      <h3 class="drawer-title">主题风格设置</h3>
    </div>
    <div class="setting-drawer-block-checbox">
      <div
        class="setting-drawer-block-checbox-item"
        @click="handleTheme('theme-dark')"
      >
        <img src="@/assets/images/dark.svg" alt="dark" />
        <div
          v-if="sideTheme === 'theme-dark'"
          class="setting-drawer-block-checbox-selectIcon"
          style="display: block"
        >
          <i aria-label="图标: check" class="anticon anticon-check">
            <svg
              viewBox="64 64 896 896"
              data-icon="check"
              width="1em"
              height="1em"
              :fill="themeColor"
              aria-hidden="true"
              focusable="false"
              class
            >
              <path
                d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"
              />
            </svg>
          </i>
        </div>
      </div>
      <div
        class="setting-drawer-block-checbox-item"
        @click="handleTheme('theme-light')"
      >
        <img src="@/assets/images/light.svg" alt="light" />
        <div
          v-if="sideTheme === 'theme-light'"
          class="setting-drawer-block-checbox-selectIcon"
          style="display: block"
        >
          <i aria-label="图标: check" class="anticon anticon-check">
            <svg
              viewBox="64 64 896 896"
              data-icon="check"
              width="1em"
              height="1em"
              :fill="theme"
              aria-hidden="true"
              focusable="false"
              class
            >
              <path
                d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"
              />
            </svg>
          </i>
        </div>
      </div>
    </div>
    <div class="drawer-item">
      <span>主题颜色</span>
      <span class="comp-style">
        <el-color-picker
          v-model="themeColor"
          :predefine="predefineColors"
          @change="themeColorChange"
        />
      </span>
    </div>
    <el-divider />

    <h3 class="drawer-title">系统布局配置</h3>

    <div class="drawer-item">
      <span>固定头部</span>
      <span class="comp-style">
        <el-switch v-model="fixedHeader" class="drawer-switch" />
      </span>
    </div>
    <div class="drawer-item">
      <span>显示搜索</span>
      <span class="comp-style">
        <el-switch v-model="searchOpen" class="drawer-switch" />
      </span>
    </div>
    <div class="drawer-item">
      <span>显示天气</span>
      <span class="comp-style">
        <el-switch v-model="weatherOpen" class="drawer-switch" />
      </span>
    </div>
    <div class="drawer-item">
      <span>网站描述</span>
      <span class="comp-style">
        <el-switch v-model="showSiteDescription" class="drawer-switch" />
      </span>
    </div>
    <div class="drawer-item">
      <span>每行数量</span>
      <el-select
        v-model="showCount"
        placeholder="选择数量"
        class="drawer-select"
        size="small"
      >
        <el-option key="1" label="1" value="1">1</el-option>
        <el-option key="2" label="2" value="2">2</el-option>
        <el-option key="3" label="3" value="3">3</el-option>
        <el-option key="4" label="4" value="4">4</el-option>
        <el-option key="6" label="6" value="6">6</el-option>
      </el-select>
    </div>

    <el-divider />

    <el-button
      icon="Refresh"
      class="reset-config"
      @click="resetSetting"
      type="primary"
    >
      重置配置
    </el-button>
  </el-drawer>
</template>

<script setup>
import { ref } from 'vue'
import { computed, getCurrentInstance } from '@vue/runtime-core'
import { useStore } from 'vuex'

import { getThemeConfig } from '@/api/client/theme'
import { update } from '@/api/nav/theme/config'
import { getToken } from '@/utils/auth'
import {
  sideTheme as defaultSideTheme,
  themeColor as defaultThemeColor,
  fixedHeader as defaultFixedHeader,
  searchOpen as defaultSearchOpen,
  weatherOpen as defaultWeatherOpen,
  showSiteDescription as defaultShowSiteDescription,
  showCount as defaultShowCount
} from '@/config/theme.config'

const { proxy } = getCurrentInstance()
const store = useStore()
const showSettings = ref(false)

const isLogin = computed(() => getToken())

const predefineColors = ref([
  '#409EFF',
  '#ff4500',
  '#ff8c00',
  '#ffd700',
  '#90ee90',
  '#00ced1',
  '#1e90ff',
  '#c71585'
])

/**
 * 主题颜色
 */
const themeColor = computed(() => {
  return store.getters['settings/themeColor']
})
/**
 * 侧边主题
 */
const sideTheme = computed(() => {
  return store.getters['settings/sideTheme']
})
// 是否需要固定头部
const fixedHeader = computed({
  get: () => store.getters['settings/fixedHeader'],
  set: (val) => {
    store.dispatch('settings/changeSetting', {
      key: 'fixedHeader',
      value: val
    })
    handleConfig()
  }
})
// 显示搜索
const searchOpen = computed({
  get: () => store.getters['settings/searchOpen'],
  set: (val) => {
    store.dispatch('settings/changeSetting', {
      key: 'searchOpen',
      value: val
    })
    handleConfig()
  }
})
// 显示天气
const weatherOpen = computed({
  get: () => store.getters['settings/weatherOpen'],
  set: (val) => {
    store.dispatch('settings/changeSetting', {
      key: 'weatherOpen',
      value: val
    })
    handleConfig()
  }
})
// 显示描述
const showSiteDescription = computed({
  get: () => store.getters['settings/showSiteDescription'],
  set: (val) => {
    store.dispatch('settings/changeSetting', {
      key: 'showSiteDescription',
      value: val
    })
    handleConfig()
  }
})
// 显示数量
const showCount = computed({
  get: () => store.getters['settings/showCount'],
  set: (val) => {
    store.dispatch('settings/changeSetting', {
      key: 'showCount',
      value: val
    })
    handleConfig()
  }
})

function themeColorChange(val) {
  store.dispatch('settings/changeSetting', {
    key: 'themeColor',
    value: val
  })
  themeColor.value = val
  handleConfig()
}
function handleTheme(val) {
  store.dispatch('settings/changeSetting', {
    key: 'sideTheme',
    value: val
  })
  sideTheme.value = val
  handleConfig()
}
async function resetSetting() {
  proxy.$modal.loading('正在清除设置缓存并刷新，请稍候...')
  localStorage.removeItem('layout-setting')
  // 设置默认值
  await handleConfig(true)
  setTimeout(() => {
    window.location.reload()
  }, 1000)
}
function openSetting() {
  showSettings.value = true
}

// 获取配置信息
async function getData() {
  const { data } = await getThemeConfig()
  store.dispatch('settings/changeSetting', {
    key: 'sideTheme',
    value: data.sideTheme
  })
  store.dispatch('settings/changeSetting', {
    key: 'themeColor',
    value: data.themeColor
  })
  store.dispatch('settings/changeSetting', {
    key: 'searchOpen',
    value: data.searchOpen
  })
  store.dispatch('settings/changeSetting', {
    key: 'weatherOpen',
    value: data.weatherOpen
  })
  store.dispatch('settings/changeSetting', {
    key: 'fixedHeader',
    value: data.fixedHeader
  })
  store.dispatch('settings/changeSetting', {
    key: 'showSiteDescription',
    value: data.showSiteDescription
  })
  store.dispatch('settings/changeSetting', {
    key: 'showCount',
    value: data.showCount
  })
}

async function handleConfig(isDefault) {
  if (!isLogin.value) {
    return
  }
  // 登录了，更新配置
  const editForm = isDefault
    ? {
        sideTheme: defaultSideTheme,
        themeColor: defaultThemeColor,
        searchOpen: defaultSearchOpen ? 1 : 2,
        weatherOpen: defaultWeatherOpen ? 1 : 2,
        fixedHeader: defaultFixedHeader ? 1 : 2,
        showSiteDescription: defaultShowSiteDescription ? 1 : 2,
        showCount: defaultShowCount
      }
    : {
        sideTheme: sideTheme.value,
        themeColor: themeColor.value,
        searchOpen: searchOpen.value ? 1 : 2,
        weatherOpen: weatherOpen.value ? 1 : 2,
        fixedHeader: fixedHeader.value ? 1 : 2,
        showSiteDescription: showSiteDescription.value ? 1 : 2,
        showCount: showCount.value
      }
  await update(editForm)
}

defineExpose({
  openSetting
})

getData()
</script>

<style lang="scss" scoped>
.setting-drawer-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  font-weight: bold;
}
.drawer-title {
  font-size: 14px;
}
.setting-drawer-block-checbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;

  .setting-drawer-block-checbox-item {
    position: relative;
    margin-right: 16px;
    border-radius: 2px;
    cursor: pointer;

    img {
      width: 48px;
      height: 48px;
    }

    .custom-img {
      width: 48px;
      height: 38px;
      border-radius: 5px;
      box-shadow: 1px 1px 2px #898484;
    }

    .setting-drawer-block-checbox-selectIcon {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      padding-top: 15px;
      padding-left: 24px;
      color: #1890ff;
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.drawer-item {
  color: rgba(0, 0, 0, 0.65);
  padding: 12px 0;
  font-size: 14px;

  .comp-style {
    float: right;
    margin: -3px 8px 0px 0px;
  }
}

.drawer-select {
  float: right;
  width: 60px;
}

.reset-config {
  float: right;
}
</style>
